import React from "react";

import Helmet from "react-helmet";

import Box from "../../../shared/Box";
import Columns from "../../../shared/Columns";
import Container from "../../../shared/Container";
import Headline from "../../../shared/Headline";
import Robots from "../../../shared/Meta/Robots";
import Page from "../../../shared/Page";

const Error404 = () => (
    <Page>
        <Helmet>
            <title>Seite nicht gefunden</title>
        </Helmet>

        <Robots index={false} />

        <Container slim>
            <Columns>
                <Columns.Full>
                    <Box>
                        <Box.Content>
                            <Headline>Seite nicht gefunden</Headline>
                            <p>
                                Die angeforderte Seite konnte nicht gefunden
                                werden.
                            </p>
                        </Box.Content>
                    </Box>
                </Columns.Full>
            </Columns>
        </Container>
    </Page>
);
export default Error404;
